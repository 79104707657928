import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { useForm, Controller } from "react-hook-form";
import { useMediaQuery, TextField } from "@material-ui/core";
import Button from "@landingpage/core/src/components/Button";

const CtaInput = ({ item }) => {
  const { handleSubmit, control } = useForm();
  const onSubmit = (data) => {
    if (!data.email ) return window.location.replace(item.cta_link.url);
    return window.location.replace(`${item.cta_link.url}?email=${data.email}`);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <div className="inputEmail">
      <Controller
        as={TextField}
        id="email"
        name="email"
        label={RichText.asText(item.input_placeholder.raw)}
        type="email"
        variant="filled"
        color="secondary"
        control={control}
        defaultValue=""
      />
      
      <Button type="submit" color="primary" >
        {RichText.asText(item.cta_title.raw)}
      </Button>
      </div>
    </form>
  );
};

const CtaList = ({ items }) => {
  const hrefLink = (href) => {
    if (href.replace("https://", "")[0] === "#")
      return href.replace("https://", "");
    return href;
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <>
      {items.map((callToAction, index) => {
        const {input_placeholder, cta_link, cta_title} = callToAction;
        const {target, url} = cta_link;
        if (input_placeholder && RichText.asText(input_placeholder.raw).length > 0) {
          return <CtaInput item={callToAction} key={index} />;
        }
        return (
          <Button
            key={index}
            color="primary"
            href={hrefLink(url)}
            target={target}
            fullWidth={isMobile}
          >
            {RichText.asText(cta_title.raw)}
          </Button>
        );
      })}
    </>
  );
};

CtaList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default CtaList;
