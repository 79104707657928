import React from "react";
import ReactCookieConsent, { Cookies } from "react-cookie-consent";
import { RichText } from "prismic-reactjs";
import { Typography, Link, useMediaQuery } from "@material-ui/core";

import Container from "../Container";

import StyledComponent from "./CookieConsent.style";

const CookieConsent = ({ cookieConsent }) => {
  if (!cookieConsent) {
    return null;
  }

  const isBrowser = typeof window !== `undefined`;
  if (isBrowser) window.dataLayer = window.dataLayer || [];

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <StyledComponent>
      <ReactCookieConsent
        containerClasses="container"
        buttonText={RichText.asText(cookieConsent.acceptbutton?.raw)}
        cookieName="cookieConsent"
        buttonClasses="acceptButton"
        buttonWrapperClasses="buttons"
        declineButtonClasses="declineButton"
        enableDeclineButton
        declineButtonText={RichText.asText(cookieConsent.declinebutton?.raw)}
        flipButtons
        contentClasses="content"
        location="bottom"
        onAccept={() => {
          Cookies.set("cookieConsent", true, 365);
          if (isBrowser) {
            window.dataLayer.push({
              event: "cookieconstent",
              cookieconsent: "true",
            });
          }
        }}
        onDecline={() => {
          Cookies.set("cookieConsent", false, 365);
          if (isBrowser) {
            window.dataLayer.push({
              event: "cookieconstent",
              cookieconsent: "false",
            });
          }
        }}
      >
        <Container>
          <div style={{ textAlign: "center" }}>
            <Typography variant="h6">
              {RichText.asText(cookieConsent.header?.raw)}{" "}
            </Typography>
            {!isMobile && (
              <Typography variant="body2">
                {RichText.asText(cookieConsent.text?.raw)}{" "}
                {cookieConsent.link_text && cookieConsent.link && (
                  <Link
                    variant="body2"
                    color="textPrimary"
                    href={cookieConsent.link.url}
                    target={cookieConsent.link.target}
                  >
                    {RichText.asText(cookieConsent.link_text?.raw)}{" "}
                  </Link>
                )}
                {RichText.asText(cookieConsent.description?.raw)}{" "}
              </Typography>
            )}
          </div>
        </Container>
      </ReactCookieConsent>
    </StyledComponent>
  );
};

CookieConsent.defaultProps = {
  cookieConsent: null,
};

CookieConsent.propTypes = {};

export default CookieConsent;
