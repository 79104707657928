import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { RichText } from "prismic-reactjs";
import { ResponsiveImage } from "../Image";
import Container from "../Container";

import StyledComponent from "./UspContent.style";

const UspContent = ({ slice }) => {
  return (
    <StyledComponent>
      <Container>
        <Grid className="uspHead">
          <Typography
            variant="h2"
            id={RichText.asText(slice.primary.contentusp_anchor.raw)}
          >
            {RichText.asText(slice.primary.block_title.raw)}
          </Typography>
          <Typography>
            {RichText.asText(slice.primary.block_description.raw)}
          </Typography>
        </Grid>
        <Grid container spacing={3} className="uspBlocks">
          {slice.items.map(({ usp_description, usp_title, usp_image }) => (
            <Grid item xs={12} md={3} key={usp_title.raw[0].text}>
              <Grid className="uspImage">
                <ResponsiveImage image={usp_image} />
              </Grid>
              <Grid className="uspText">
                <Typography variant="h5">
                  {RichText.asText(usp_title.raw)}
                </Typography>
                <Typography>{RichText.asText(usp_description.raw)}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledComponent>
  );
};

UspContent.propTypes = {};

export default UspContent;
