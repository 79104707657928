import React from "react";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Grid, Typography } from "@material-ui/core";

import Container from "@landingpage/core/src/components/Container";

import {
  ResponsiveImage,
  ResponsiveBackgroundImage,
} from "@landingpage/core/src/components/Image";
import UspList from "./parts/UspList";

import StyledComponent from "./Header.style";

const Content = ({ header }) => {
  return (
    <Grid
      container
      className="textContainer"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid container direction="column">
          <Grid className="mobile-center" item>
            <Typography className="title" variant="h1">
              {RichText.asText(header.title.raw)}
            </Typography>
            <Typography
              className="description"
              variant="body1"
              component="div"
              gutterBottom
            >
              {RichText.render(header.description.raw)}
            </Typography>
          </Grid>
          <Grid item>
            <UspList items={header.unique_selling_points} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Header = ({ header }) => {
  if (!header) return null;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <StyledComponent className="default">
      <Container paddingRight={false}>
        {!isMobile && (
          <ResponsiveBackgroundImage image={header.image} position="top right">
            <Content header={header} />
          </ResponsiveBackgroundImage>
        )}
        {isMobile && (
          <>
            <ResponsiveImage image={header.image} />
            <Content header={header} />
          </>
        )}
      </Container>
    </StyledComponent>
  );
};

Header.defaultProps = {
  header: null,
};

Header.propTypes = {};

export default Header;
