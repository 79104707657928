import styled from "styled-components";

const StyledComponent = styled.div`
  *:focus {
    outline: none;
  }

  .rightControl {
    background: white;
    margin-right: -15px;
  }

  .leftControl {
    margin-left: -15px;
  }

  .controlButton {
    display: inline-block;
    padding: 0;

    color: black;
    cursor: pointer;
    background: ${({ theme }) => `${theme.palette.background.slider}`};
    border: none;
    border-radius: 100%;

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
      cursor: default;
    }

    .icon {
      font-size: ${({ theme }) => `${theme.typography.pxToRem(80)}`};
      color: ${({ theme }) => `${theme.palette.slider.arrow}`};
    }
  }

  .slider-control-centerright,
  .slider-control-centerleft {
    z-index: 100;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .controlButton {
      .icon {
        font-size: ${({ theme }) => `${theme.typography.pxToRem(35)}`};
      }
    }
  }
`;

export default StyledComponent;
