import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "../Button";

import StyledComponent from "./Faq.style";
import Container from "../Container";

const FaqItem = ({ question, description }) => {
  return (
    <Accordion className="expansion-panel">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{RichText.asText(question.raw)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div">
          {RichText.render(description.raw)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const CtaButton = ({ slice }) => {
  if (!slice.cta_title || !slice.target) return null;

  return (
    <Grid item xs={12}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        href={slice.cta_link.url}
        target={slice.cta_link.target}
      >
        {RichText.asText(slice.cta_title.raw)}
      </Button>
    </Grid>
  );
};

const Faq = ({ slice }) => {
  return (
    <StyledComponent>
      <Container top bottom>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              id={RichText.asText(slice.primary?.faq_anchor?.raw)}
            >
              {RichText.asText(slice.primary.faq_title.raw)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {slice.items.map((item, index) => (
              <FaqItem
                key={index}
                question={item.faq_question}
                description={item.faq_description}
              />
            ))}
          </Grid>

          <Grid item xs={12}>
            <Container maxWidth="sm">
              <CtaButton slice={slice.primary} />
            </Container>
          </Grid>
        </Grid>
      </Container>
    </StyledComponent>
  );
};

Faq.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      faq_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        faq_question: PropTypes.shape({
          raw: PropTypes.array.isRequired,
        }).isRequired,
        faq_description: PropTypes.shape({
          raw: PropTypes.array.isRequired,
        }).isRequired,
      })
    ),
  }).isRequired,
};

export default Faq;
