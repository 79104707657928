import styled from "styled-components";

const StyledComponent = styled.div`
  .uspHead {
    text-align: center;
    padding-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
  }

  .uspBlocks {
    text-align: center;
    justify-content: center;
    align-items: baseline;
    padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
    padding-bottom: ${({ theme }) => `${theme.spacing(4)}px`};
  }

  .uspImage {
    img{
      width: 80px;
      height: 80px;
    }
  }

  .MuiTypography-h2 {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .MuiTypography-h5 {
    color: ${({ theme }) => theme.palette.text.uspHeader};
  }
`;

export default StyledComponent;
