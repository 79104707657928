import styled from "styled-components";

const StyledComponent = styled.div`
  ul {
    li {
      padding: 0;

      .MuiListItemIcon-root {
        min-width: 44px;
      }

      p {
        margin: 0;
      }

      > div {
        > svg {
          color: ${({ theme }) => theme.palette.color.green};
          width: 1.2em;
          height: 1.2em;
        }
        > span {
          color: ${({ theme }) => theme.palette.pricing.uspText};
          font-size: ${({ theme }) => theme.typography.body1.fontSize};
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .usp-list-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export default StyledComponent;
