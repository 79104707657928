import React, { useState } from "react";
import { Container, Tab, Grid, Tabs, Box, Typography } from "@material-ui/core";
import { RichText } from "prismic-reactjs";
import { Image } from "@landingpage/core/src/components/Image";

import StyledComponent from "./Tabs.style";

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      key={index}
    >
      {value === index && (
        <Box p={3} className="content">
          {children}
        </Box>
      )}
    </div>
  );
};

const TabsComponent = ({ slice }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <StyledComponent>
      <div className="tabBar" id="tabBarAnchor">
        <Container maxWidth="xl" className="container" disableGutters>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
            centered
            scrollButtons="auto"
          >
            {slice?.items.map((item, index) => (
              <Tab
                key={index}
                label={
                  <span className="tabText">
                    {RichText.asText(item.tab.raw)}
                  </span>
                }
                className="tabItem"
                icon={
                  <div className="imageWrapper">
                    <img src={item.tab_icon.url} alt="Tab icon" />
                  </div>
                }
              />
            ))}
          </Tabs>
        </Container>
      </div>
      {slice?.items.map((item, index) => (
        <TabPanel value={value} index={index} key={index}>
          <Container maxWidth="xl" className="container">
            <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
              <Grid item md={5} sm={12}>
                <Image className="image" image={item.image} />
              </Grid>
              <Grid item md={7} sm={12}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    md={
                      item?.content_right?.raw &&
                      item?.content_right?.raw.length > 0
                        ? 6
                        : 12
                    }
                    sm={12}
                  >
                    <Typography
                      className="content"
                      variant="body2"
                      component="div"
                    >
                      {RichText.render(item?.content_left?.raw)}
                    </Typography>
                  </Grid>
                  {item?.content_right?.raw &&
                    item?.content_right?.raw.length > 0 && (
                      <Grid item md={6} sm={12}>
                        <Typography
                          className="content"
                          variant="body2"
                          component="div"
                        >
                          {RichText.render(item?.content_right?.raw)}
                        </Typography>
                      </Grid>
                    )}
                  <Grid item md={12}>
                    <Typography
                      className="content"
                      variant="body2"
                      component="div"
                    >
                      {RichText.render(item?.content_botom?.raw)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
      ))}
      <div className="logoBar">
        <div className="logoWrapper">
          <Image image={slice?.primary?.company_logos} />
        </div>
      </div>
    </StyledComponent>
  );
};

TabsComponent.defaultProps = {
  header: null,
};

TabsComponent.propTypes = {};

export default TabsComponent;
