import styled from "styled-components";
import { transparentize } from "polished";

const StyledComponent = styled.div`
  .quote-text {
    > p {
      margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
    }
  }
  .MuiTypography-h2 {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  .quote-person {
    small {
      padding-top: ${({ theme }) => `${theme.spacing(1)}px`};
      display: inline-block;
      border-top: 1px solid rgba(255, 255, 255, 0.8);
    }
  }

  a:link {
    color: ${({ theme }) => theme.palette.text.header};
  }
  a:visited {
    color: ${({ theme }) => theme.palette.text.header};
  }

  .call-to-action {
    margin-top: ${({ theme }) => `${theme.spacing(2)}px`};

    &.white {
      background-color: ${({ theme }) => theme.palette.primary.contrastText};
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.readMoreButton {
      margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
      border: 2px solid white;

      .readMoreIcon {
        margin-left: ${({ theme }) => `${theme.spacing()}px`};
      }
    }
  }

  &.open {
    & > .MuiContainer-root > .MuiGrid-root {
      align-items: flex-start;
    }
    .gatsby-image-wrapper {
      margin-top: ${({ theme }) => `${theme.spacing(16)}px`};
    }
  }

  .image-left {
    .text {
      padding-left: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    &.image-fade {
      .text {
        padding-left: 10%;
        background: ${({ theme }) =>
          `linear-gradient(270deg, ${
            theme.palette.background.default
          } 70%, ${transparentize(1, theme.palette.background.default)} 100%)`};
      }
    }
  }
  .image-right {
    .text {
      padding-right: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    &.image-fade {
      .text {
        padding-right: 10%;
        background: ${({ theme }) =>
          `linear-gradient(90deg, ${
            theme.palette.background.default
          } 70%, ${transparentize(1, theme.palette.background.default)} 100%)`};
      }
    }
  }
  .image-top {
    &.slice-container {
      padding-top: ${({ theme }) => `${theme.spacing(12)}px`};
    }

    .text {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .image-bottom {
    &.slice-container {
      padding-bottom: ${({ theme }) => `${theme.spacing(12)}px`};
    }

    .text {
      padding-bottom: 0;
    }
  }

  .maxWidthContainer {
    display: flex;
    justify-content: center;
  }

  .maxWidthChildren {
    max-width: 980px;
  }

  .image-default {
    &.image-left > .image {
      padding-left: ${({ theme }) => `${theme.spacing(6)}px`};
    }
    &.image-right > .image {
      padding-right: ${({ theme }) => `${theme.spacing(6)}px`};
    }
    &.image-top,
    &.image-bottom {
      > .image {
        padding: ${({ theme }) => `0px ${theme.spacing(6)}px`};
      }
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    .image-left {
      .text {
        padding-left: ${({ theme }) => `${theme.spacing(8)}px`};
      }
    }
    .image-right {
      .text {
        padding-right: ${({ theme }) => `${theme.spacing(8)}px`};
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    .image-default {
      &.image-left > .image {
        padding-left: ${({ theme }) => `${theme.spacing(4)}px`};
      }
      &.image-right > .image {
        padding-right: ${({ theme }) => `${theme.spacing(4)}px`};
      }
      &.image-top,
      &.image-bottom {
        > .image {
          padding: ${({ theme }) => `0px ${theme.spacing(4)}px`};
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .slice-container {
      margin: 0px !important;
      padding: 0px !important;
    }

    .text {
      margin: 0px !important;
      padding: 0px !important;
    }

    .call-to-action {
      margin-top: ${({ theme }) => `${theme.spacing(6)}px`};
    }

    .image-fade {
      .gatsby-image-wrapper {
        margin: ${({ theme }) =>
          `-${theme.spacing(8)}px 0px -${theme.spacing(4)}px 0px`};
        z-index: -1;
      }

      .text {
        background: transparent !important;
      }
    }

    .image-default {
      .gatsby-image-wrapper {
        margin: ${({ theme }) => `0px ${theme.spacing(2)}px`};
      }
    }
  }

  &.background-white {
    background-color: white;

    a:link {
      color: ${({ theme }) => theme.palette.text.secondary};
      .MuiButton-label{
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }
    a:visited {
      color: ${({ theme }) => theme.palette.text.secondary};
      .MuiButton-label{
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    .MuiTypography-root {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`;

export default StyledComponent;
