import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Container, Grid, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import {
  ResponsiveImage,
  ResponsiveBackgroundImage,
} from "@landingpage/core/src/components/Image";
import FacebookIcon from "../../images/facebook.svg";
import InstagramIcon from "../../images/instagram.svg";
import TwitterIcon from "../../images/twitter.svg";

import StyledComponent from "./ContentBlockMap.style";

const Text = ({ slice, imageDirection, isMobile }) => {
  return (
    <>
      <Typography variant="h2">
        {RichText.asText(slice.primary.content_title.raw)}
      </Typography>

      {!isMobile && imageDirection === "top" && (
        <ResponsiveImage
          className="image"
          image={slice.primary.content_image}
        />
      )}

      <Typography variant="body1" component="div" gutterBottom>
        {RichText.render(slice.primary.content_description.raw)}
      </Typography>

      <Quote
        quote={slice.primary.quote_text}
        person={slice.primary.quote_person}
      />

      <CallToActions items={slice.items} />

      {!isMobile && imageDirection === "bottom" && (
        <ResponsiveImage
          className="image"
          image={slice.primary.content_image}
        />
      )}
    </>
  );
};

const Quote = ({ quote, person }) => {
  if (!quote?.raw?.length) return null;

  return (
    <>
      <Typography className="quote-text" variant="h4" component="div">
        {RichText.render(quote.raw)}
      </Typography>
      <Typography className="quote-person" variant="body1" gutterBottom>
        <small>{RichText.asText(person.raw)}</small>
      </Typography>
    </>
  );
};

const CallToActions = ({ items }) => {
  if (!items?.length) return null;

  return items.map((item, index) => {
    switch (item.select) {
      case "Facebook":
        return (
          <Link key={index} href={item.cta_link.url} target="_blank">
            <FacebookIcon height="40" width="40" />
          </Link>
        );
      case "Instagram":
        return (
          <Link key={index} href={item.cta_link.url} target="_blank">
            <InstagramIcon height="40" width="40" />
          </Link>
        );
      case "Twitter":
        return (
          <Link key={index} href={item.cta_link.url} target="_blank">
            <TwitterIcon height="40" width="40" />
          </Link>
        );
      default:
        return null;
    }
  });
};

const columnSizes = (sizes, inverse = false) => {
  const calculateSize = (size, inverseValue) => {
    if (inverseValue && size < 12) {
      return 12 - size;
    }

    return size;
  };

  return {
    xs: calculateSize(sizes.xs, inverse),
    sm: calculateSize(sizes.sm, inverse),
    md: calculateSize(sizes.md, inverse),
    lg: calculateSize(sizes.lg, inverse),
    xl: calculateSize(sizes.xl, inverse),
  };
};

const ContentBlockMap = ({ slice }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const imageDirection = slice.primary.image_direction;
  const imageType = slice.primary.image_type;

  let columns = {
    xs: 12,
    sm: 12,
    md: parseInt(slice?.primary?.grid_medium) || 7,
    lg: parseInt(slice?.primary?.grid_large) || 6,
    xl: parseInt(slice?.primary?.grid_xl) || 5,
  };

  const classNames = [
    `image-${imageDirection}`,
    `image-${imageType}`,
    "slice-container",
    "mobile-center",
  ];

  let direction = "row";
  let position = "center right";

  if (["left", "top"].indexOf(imageDirection) > -1) {
    direction = "row-reverse";
    position = "center left";
  }
  if (["bottom", "top"].indexOf(imageDirection) > -1) {
    columns = {
      ...columns,
      md: 12,
      lg: 12,
      xl: 12,
    };
    position = "center bottom";
    classNames.push("center");
  }

  return (
    <StyledComponent>
      <Container
        maxWidth="xl"
        disableGutters
        className={`background-${slice.primary.background_color || "default"}`}
      >
        {imageType === "fade" && !isMobile && (
          <ResponsiveBackgroundImage
            image={slice.primary.content_image}
            position={position}
          >
            <Grid
              className={classNames.join(" ")}
              container
              direction={direction}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="text" item {...columnSizes(columns)}>
                <Text slice={slice} />
              </Grid>
            </Grid>
          </ResponsiveBackgroundImage>
        )}

        {imageType === "default" &&
          ["right", "left"].indexOf(imageDirection) > -1 &&
          !isMobile && (
            <Grid
              className={classNames.join(" ")}
              container
              direction={direction}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="text" item {...columnSizes(columns)}>
                <Text slice={slice} />
              </Grid>
              <Grid className="image" item {...columnSizes(columns, true)}>
                <ResponsiveImage image={slice.primary.content_image} />
              </Grid>
            </Grid>
          )}

        {imageType === "default" &&
          ["top", "bottom"].indexOf(imageDirection) > -1 &&
          !isMobile && (
            <Grid className={classNames.join(" ")} container>
              <Grid className="text" item xs={12}>
                <Text imageDirection={imageDirection} slice={slice} />
              </Grid>
            </Grid>
          )}

        {isMobile && (
          <Grid
            className={classNames.join(" ")}
            container
            direction={direction}
            alignItems="center"
          >
            <Grid className="text" item {...columnSizes(columns)}>
              <Text isMobile slice={slice} />
            </Grid>
          </Grid>
        )}
      </Container>
    </StyledComponent>
  );
};

ContentBlockMap.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string.isRequired,

    primary: PropTypes.shape({
      content_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
      content_description: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,

      quote_text: PropTypes.shape({
        raw: PropTypes.array,
      }),
      quote_person: PropTypes.shape({
        raw: PropTypes.array,
      }),
    }).isRequired,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        cta_link: PropTypes.shape({
          url: PropTypes.string,
          target: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};

export default ContentBlockMap;
