import React from "react";
import PropTypes from "prop-types";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { RichText } from "prismic-reactjs";

import StyledComponent from "./UspList.style";

const UspList = ({ items }) => {
  if (!items) return null;

  return (
    <StyledComponent>
      <List dense disablePadding>
        {items.map((item, index) => (
          <ListItem key={index} disableGutters>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={RichText.asText(item.usp_title.raw)} />
          </ListItem>
        ))}
      </List>
    </StyledComponent>
  );
};

UspList.defaultProps = {
  items: [],
};

UspList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      usp_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired
  ),
};

export default UspList;
