import React from "react";

// Headers
import Header from "./Header";
import HeaderHomepageAB from "./HeaderHomepageAB";
import HeaderImage from "./HeaderImage";
import HeaderVideo from "./HeaderVideo";

const HeaderFactory = ({ slice }) => {
  if (!slice) return null;
  return (
    {
      image: <HeaderImage header={slice} />,
      video: <HeaderVideo header={slice} />,
      'homepage-ab': <HeaderHomepageAB header={slice} />,
    }[slice.type] || <Header header={slice} />
  );
};

export default HeaderFactory;
