import styled from "styled-components";

const StyledComponent = styled.main`
  ${({ theme }) => theme.breakpoints.down("sm")} {
    > div {
      margin: 0;
      padding: ${({ theme }) => `${theme.spacing(4)}px 0px`};
    }
  }
`;

export default StyledComponent;
