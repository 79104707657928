import React from "react";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Grid, Typography } from "@material-ui/core";

import Container from "@landingpage/core/src/components/Container";
import { ResponsiveImage } from "@landingpage/core/src/components/Image";

import UspList from "./parts/UspList";
import CtaList from "./parts/CtaList";
import tvImg from "./tv.png";

import StyledComponent from "./Header.style";

const VideoDevice = ({ header }) => {
  return (
    <Grid className="imagePosition">
      <div className="deviceImage">
        {header.video && header.video.url ? (
          <>
            <img
              style={{
                position: "relative",
                zIndex: 2,
                width: "100%",
              }}
              alt="Tv screen"
              src={tvImg}
            />
            <div
              style={{
                width: "99.5%",
                height: "100%",
                maxHeight: "80.5%",
                position: "absolute",
                top: "4%",
                left: "0%",
              }}
            >
              <video
                style={{ height: "100%", width: "100%" }}
                autoPlay
                playsInline
                muted
                loop
              >
                <source src={header.video.url} type="video/mp4" />
              </video>
            </div>
          </>
        ) : (
          <ResponsiveImage image={header.image} />
        )}
      </div>
    </Grid>
  );
};

const Content = ({ header }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  return (
    <Grid
      container
      className="textContainer"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid className="contentCenter" item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid container direction="column" spacing={4}>
          <Grid className="mobile-center" item>
            <Typography className="title" variant="h1">
              {RichText.asText(header.title.raw)}
            </Typography>
            {isMobile && (
              <Grid item className="contentCenter">
                <VideoDevice header={header} />
              </Grid>
            )}
            <Typography
              className="description"
              variant="body1"
              component="div"
              gutterBottom
            >
              {RichText.render(header.description.raw)}
            </Typography>
          </Grid>

          {header.unique_selling_points.length > 0 && (
            <Grid item>
              <UspList items={header.unique_selling_points} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid
          item
          className="contentRight"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <VideoDevice header={header} />
        </Grid>
      )}
    </Grid>
  );
};

const HeaderVideo = ({ header }) => {
  if (!header) return null;

  return (
    <StyledComponent>
      <Container>
        <Content header={header} />
      </Container>
    </StyledComponent>
  );
};

HeaderVideo.defaultProps = {
  header: null,
};

HeaderVideo.propTypes = {};

export default HeaderVideo;
