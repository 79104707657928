import styled from "styled-components";

const StyledComponent = styled.div`
  .tabBar {
    box-shadow: inset 0px -100px 84px -90px rgba(255, 255, 255, 0.75);
    width: 100%;

    .container {
      display: flex;
      justify-content: center;
    }

    .tabItem {
      background: linear-gradient(180deg, white 0%, #d9e0e8 100%);
      margin-right: ${({ theme }) => `${theme.spacing(0.5)}px`};
      width: 220px;
      min-width: 0;

      &:last-child {
        margin-right: 0;
      }

      .imageWrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 100%;
      }

    }

    &.Mui-selected {
      background: white;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }

  .content {
    background: white;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .MuiGrid-root {
    align-self: flex-start;
  }

  .logoBar {
    background: ${({ theme }) => theme.palette.primary.main};
    display: flex;
    justify-content: center;
    .logoWrapper {
      width: 90%;
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    .MuiTabs-root {
      flex: 1;
    }

    .tabItem {
      width: 0;
    }

    .tabText {
      display: none;
    }
  }
`;

export default StyledComponent;
