import styled from "styled-components";
import { transparentize } from "polished";

const StyledComponent = styled.div`
  .root {
    padding-top: 144%;
    display: block;
    :hover {
      cursor: pointer;
    }
  }

  .movieList {
    padding-left: ${({ theme }) => `${theme.spacing(5)}px`};
    padding-right: ${({ theme }) => `${theme.spacing(5)}px`};
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  .movie {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }

  .container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  .fadeContainer {
    position: relative;
  }

  .fadeEffect {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 99;

    &.left {
      left: 0;
      background: ${({ theme }) =>
        `linear-gradient(90deg, ${
          theme.palette.background.slider
        } 30%, ${transparentize(1, theme.palette.background.slider)} 100%)`};
    }

    &.right {
      right: 0;
      background: ${({ theme }) =>
        `linear-gradient(270deg, ${
          theme.palette.background.slider
        } 30%, ${transparentize(1, theme.palette.background.slider)} 100%)`};
    }
  }

  .title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.palette.slider.text.primary};
    text-transform: uppercase;
    margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  }

  .contentTitle {
    margin-bottom: ${({ theme }) => `${theme.spacing(0)}px`};
  }

  .title:before,
  .title:after {
    content: "";
    border-top: 2px solid;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
  }

  .title:after {
    margin: 0 0 0 20px;
  }

  .collectionText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};

    p {
      color: ${({ theme }) => theme.palette.text.primary};
      text-align: center;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: ${({ theme }) => `${theme.spacing(5)}px`};
    padding-right: ${({ theme }) => `${theme.spacing(5)}px`};

    h2 {
      color: ${({ theme }) => theme.palette.slider.text.primary};
    }

    .contentText {
      color: ${({ theme }) => theme.palette.text.primary};
      text-align: center;
    }

    .call-to-action {
      margin-top: ${({ theme }) => `${theme.spacing(2)}px`};

      &.white {
        background-color: ${({ theme }) => theme.palette.primary.contrastText};
        color: ${({ theme }) => theme.palette.primary.main};
      }
    }
  }

  .slice-container {
    padding-top: ${({ theme }) => `${theme.spacing(0)}px`};
    padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .slice-container {
      padding-top: 0;
    }

    .content {
      text-align: center;
    }

    .fadeEffect {
      width: 20px;
    }
  }
`;

export default StyledComponent;
