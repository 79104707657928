import styled from "styled-components";

const StyledComponent = styled.div`
  .planBox {
    border: 2px solid white;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    border: ${({ theme }) => `2px solid ${theme.palette.pricing.yearDefault}`};
    opacity: 0.7;
    padding: 0;

    &:hover {
      border: ${({ theme }) => `2px solid ${theme.palette.color.green}`};
    }

    .content {
      padding: ${({ theme }) => `${theme.spacing(2)}px`};
    }

    .MuiTypography-root {
      color: ${({ theme }) => theme.palette.pricing.uspText};
    }

    .ribbonContainer {
      display: flex;
      justify-content: center;
    }

    .yearRibbon {
      background-color: ${({ theme }) => theme.palette.pricing.yearDefault};
      padding: ${({ theme }) =>
        `${theme.spacing(0.5)}px ${theme.spacing(1)}px`};
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      width: 100%;
      text-align: center;

      .MuiTypography-h6 {
        color: white;
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    .priceContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .plan-price {
        font-weight: bold;
        font-size: 2.5em;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-size: 17px;
          text-transform: uppercase;
          margin-left: ${({ theme }) => `${theme.spacing()}px`};
          font-weight: 500;
        }
      }

      .checkedRadioIcon {
        color: ${({ theme }) => theme.palette.color.green};
        margin-right: ${({ theme }) => `${theme.spacing(1.5)}px`};
      }

      .radioButtonUnchecked {
        margin-right: ${({ theme }) => `${theme.spacing(1.5)}px`};
        color: ${({ theme }) => theme.palette.pricing.yearDefault};
      }
    }

    &.selected {
      border: ${({ theme }) => `2px solid ${theme.palette.color.green}`};
      opacity: 1;

      .priceContainer {
        border-color: ${({ theme }) => theme.palette.color.green};
      }

      .yearRibbon {
        background-color: ${({ theme }) => theme.palette.color.green};
      }
    }
  }

  .ribbon-wrapper {
    width: 109px;
    height: 115px;
    overflow: hidden;
    position: absolute;
    top: -11px;
    right: -8px;
  }

  .ribbon {
    text-align: center;
    text-transform: uppercase;
    transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    left: -11px;
    top: 26px;
    width: 156px;
    background-color: ${({ theme }) => theme.palette.pricing.ribbonColor};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .MuiTypography-root {
      color: #fff;
      font-size: 1em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .ribbon:before,
  .ribbon:after {
    content: "";
    border-top: ${({ theme }) =>
      `4px solid ${theme.palette.pricing.ribbonShadow}`};
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
  }

  .ribbon:before {
    left: 0;
  }

  .ribbon:after {
    right: 0;
  }
`;

export default StyledComponent;
