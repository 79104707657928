import React, { useEffect, useState } from "react";
import Carousel from "nuka-carousel";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import StyledComponent from "./Slider.style";

const CenterLeftControls = ({
  onClick,
  currentSlide,
  wrapAround,
  slideCount,
}) => {
  const disabled = (currentSlide === 0 && !wrapAround) || slideCount === 0;

  return (
    <button
      className="leftControl controlButton"
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <KeyboardArrowLeft className="icon" />
    </button>
  );
};

const CenterRightControls = ({
  onClick,
  currentSlide,
  wrapAround,
  slideCount,
  slidesToScroll,
}) => {
  const disabled = currentSlide + slidesToScroll >= slideCount && !wrapAround;

  return (
    <button
      className="rightControl controlButton"
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <KeyboardArrowRight className="icon" />
    </button>
  );
};

const Slider = ({ children, slidesToShow, cellSpacing, aspectRatio }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  }, []);

  const carouselProps = {
    slidesToShow,
    cellSpacing,
    aspectRatio,
    slideIndex,
    heightMode: "max",
    slidesToScroll: 2,
    afterSlide: (index) => setSlideIndex(index),
    framePadding: "0px",
    wrapAround: false,
    easing: "easeQuadOut",
    edgeEasing: "easeQuadOut",
    renderBottomCenterControls: null,
    renderCenterLeftControls: null,
    renderCenterRightControls: null,
    initialSlideHeight: 330,
  };

  return (
    <StyledComponent>
      <Carousel
        {...carouselProps}
        dragging={false}
        renderCenterLeftControls={({
          previousSlide,
          currentSlide,
          wrapAround,
          slideCount,
        }) => (
          <CenterLeftControls
            onClick={previousSlide}
            currentSlide={currentSlide}
            wrapAround={wrapAround}
            slideCount={slideCount}
          />
        )}
        renderCenterRightControls={({
          nextSlide,
          currentSlide,
          wrapAround,
          slideCount,
          slidesToScroll,
        }) => (
          <CenterRightControls
            onClick={nextSlide}
            currentSlide={currentSlide}
            wrapAround={wrapAround}
            slideCount={slideCount}
            slidesToScroll={slidesToScroll}
          />
        )}
      >
        {React.Children.map(children, (child, index) => (
          <div key={index}>{child}</div>
        ))}
      </Carousel>
    </StyledComponent>
  );
};

Slider.defaultProps = {
  slidesToShow: 3,
  slidesToScroll: 0,
  cellSpacing: 16,
};

export default Slider;
