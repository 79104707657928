import React from "react";
import PropTypes from "prop-types";

import Pricing from "@landingpage/core/src/components/Pricing";
import Faq from "@landingpage/core/src/components/Faq";
import Movies from "@landingpage/core/src/components/Movies";
import Header from "@landingpage/core/src/components/Header";
import UspContent from "@landingpage/core/src/components/UspContent";
import MovieSliderImages from "@landingpage/core/src/components/MovieSliderImages";
import ContentBlock from "../ContentBlock";
import ContentBlockMap from "../ContentBlockMap";
import Tabs from "../Tabs";

import StyledComponent from "./Slices.style";

const Slices = ({ slices }) => {
  return (
    <StyledComponent>
      {slices.map((slice, index) => {
        switch (slice.slice_type) {
          case "content":
            return <ContentBlock key={index} slice={slice} />;
          case "movie_slider":
            return <Movies key={index} slice={slice} />;
          case "movie_slider_1":
            return <MovieSliderImages key={index} slice={slice} />;
          case "content1":
            return <ContentBlockMap key={index} slice={slice} />;
          case "contentusp":
            return <UspContent key={index} slice={slice} />;
          case "header":
            return <Header key={index} header={slice} />;
          case "pricing":
            return <Pricing key={index} slice={slice} />;
          case "faq":
            return <Faq key={index} slice={slice} />;
          case "tabs":
            return <Tabs key={index} slice={slice} />;

          default:
            return null;
        }
      })}
    </StyledComponent>
  );
};

Slices.propTypes = {
  slices: PropTypes.arrayOf(
    PropTypes.shape({
      slice_type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Slices;
