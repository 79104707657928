import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RichText } from "prismic-reactjs";
import { Typography, Container } from "@material-ui/core";
import Button from "@landingpage/core/src/components/Button";
import Slider from "../Slider";
import MovieDialog from "./MovieDialog";

import StyledComponent from "./Movies.style";

const columnMapping = {
  landscape: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 4,
    xl: 6,
  },
  portrait: {
    xs: 2,
    sm: 2,
    md: 5,
    lg: 7,
    xl: 10,
  },
};

const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key), {
        noSsr: true,
      });
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

const MovieSlider = ({ item }) => {
  const [collections, setCollections] = useState({});
  const width = useWidth();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true
  });

  const handleDialogOpen = (asset) => {
    setSelectedAsset(asset);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedAsset(null);
    setOpen(false);
  };

  useEffect(() => {
    const api = axios.create({
      baseURL: process.env.GATSBY_APP_CATALOG_URL,
    });

    api
      .get(
        `v1/collection/${item.collection_id}?country=${window.location.href
          .match(/\/[a-z]{2}-[a-z]{2}/g)[0]
          .split("-")[1]
          .toUpperCase()
          .replace("/", "")}`
      )
      .then((response) => setCollections(response.data));
  }, []);

  if (!collections.items) return null;

  return (
    <div className="movieList" key={collections.id}>
      {item.collection_title.raw &&
        item.collection_title.raw.length > 0 &&
        RichText.asText(item.collection_title.raw) !== "" && (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Typography variant="h3" className="title">
              {RichText.asText(item.collection_title.raw)}
            </Typography>
          </div>
        )}

      {item.text_placement === "above" && (
        <Container
          maxWidth="lg"
          className="collectionText"
          style={{ marginTop: "0px" }}
        >
          <Typography variant="body1" component="div" gutterBottom>
            {RichText.render(item?.collection_text?.raw)}
          </Typography>
        </Container>
      )}

      <div className="fadeContainer">
        <div className="fadeEffect left" />
        <div className="fadeEffect right" />
        <Slider
          slidesToShow={columnMapping.portrait[width]}
          aspectRatio={1.44}
          heightMode="max"
        >
          {collections.items
            .slice(0, isMobile ? 6 : collections.items.length)
            .map((movie) => {
              const listImage = `${process.env.GATSBY_APP_RESOURCES_URL}/${movie.id}/list/360x520/${movie.list_image}.jpeg`;
              const coverImage = `${process.env.GATSBY_APP_RESOURCES_URL}/${movie.id}/cover/360x520/${movie.cover_image}.jpeg`;

              return movie.list_image ? (
                <div
                  className="root"
                  key={movie.id}
                  onClick={() => handleDialogOpen(movie)}
                  role="presentation"
                >
                  <div
                    className="movie"
                    style={{
                      backgroundImage: `url(${
                        movie.list_image ? listImage : coverImage
                      })`,
                    }}
                  />
                  <div className="container">
                    <div style={{ height: 50 }} />
                  </div>
                </div>
              ) : (
                <div className="root" key={movie.id} />
              );
            })}
        </Slider>
      </div>

      {item.text_placement === "below" && (
        <Container maxWidth="lg" className="collectionText">
          <Typography variant="body1" component="div" gutterBottom>
            {RichText.render(item.collection_text.raw)}
          </Typography>
        </Container>
      )}

      <MovieDialog
        open={Boolean(selectedAsset)}
        handleClose={handleClose}
        asset={selectedAsset}
      />
    </div>
  );
};

const CallToActions = ({ item }) => {
  if (
    !item.cta_title ||
    item.cta_title.raw.length === 0 ||
    item.cta_link.url.length === 0
  )
    return null;
  const classNames = ["call-to-action"];

  if (item.cta_color !== "default") {
    classNames.push(item.cta_color);
  }

  return (
    <Button
      className={classNames.join(" ")}
      variant="contained"
      color="primary"
      href={item.cta_link.url}
      target={item.cta_link.target}
    >
      {RichText.asText(item.cta_title.raw)}
    </Button>
  );
};

const Movies = ({ slice }) => {
  return (
    <StyledComponent>
      <Container maxWidth="xl" disableGutters className="slice-container">
        <Container maxWidth="lg" className="content">
          <Typography
            className="contentTitle"
            variant="h2"
            id={RichText.asText(slice?.primary?.movieslider_anchor?.raw || [])}
          >
            {RichText.asText(slice?.primary?.movie_title?.raw || [])}
          </Typography>
          {slice?.primary?.movie_text?.raw[0]?.text.length > 0 && (
            <Typography
              variant="body1"
              component="div"
              gutterBottom
              className="contentText"
            >
              {RichText.render(slice?.primary?.movie_text?.raw || [])}
            </Typography>
          )}
        </Container>
        {slice.items.map((item, index) => (
          <MovieSlider item={item} key={index} />
        ))}
        <Container maxWidth="lg" className="content">
          <CallToActions item={slice?.primary} />
        </Container>
      </Container>
    </StyledComponent>
  );
};

Movies.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string.isRequired,
    primary: PropTypes.shape({
      movie_text: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
      movie_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
      items: PropTypes.arrayOf({}),
    }).isRequired,
  }).isRequired,
};

export default Movies;
