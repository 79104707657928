import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { RichText } from "prismic-reactjs";
import { Typography, Container } from "@material-ui/core";
import Button from "@landingpage/core/src/components/Button";
import Slider from "../Slider";

import StyledComponent from "./MovieSliderImages.style";

const columnMapping = {
  landscape: {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 6,
  },
  portrait: {
    xs: 2,
    sm: 2,
    md: 5,
    lg: 7,
    xl: 7,
  },
};

const useWidth = () => {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key), {
        noSsr: true,
      });
      return !output && matches ? key : output;
    }, null) || "xs"
  );
};

const MovieSlider = ({ items }) => {
  const width = useWidth();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  return (
    <div className="movieList">
      <Container
        maxWidth="lg"
        className="collectionText"
        style={{ marginTop: "0px" }}
      />

      <div className="fadeContainer">
        <div className="fadeEffect left" />
        <div className="fadeEffect right" />
        <Slider
          slidesToShow={columnMapping.portrait[width]}
          aspectRatio={1.44}
          heightMode="max"
        >
          {items.slice(0, isMobile ? 6 : items.length).map((movie) => {
            const { slider_image } = movie;
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="root" role="presentation" >
                <div
                  className="movie"
                  style={{
                    backgroundImage: `url(${slider_image.fluid.src})`,
                  }}
                />
                <div className="container">
                  <div style={{ height: 50 }} />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

const CallToActions = ({ item }) => {
  if (
    !item.cta_slider_title ||
    item.cta_slider_title.raw.length === 0 ||
    item.cta_slider_link.url.length === 0
  )
    return null;
  const classNames = ["call-to-action"];

  if (item.cta_color !== "default") {
    classNames.push(item.cta_slider_color);
  }

  return (
    <Button
      className={classNames.join(" ")}
      variant="contained"
      color="primary"
      href={item.cta_slider_link.url}
      target={item.cta_slider_link.target}
    >
      {RichText.asText(item.cta_slider_title.raw)}
    </Button>
  );
};

const MovieSliderImages = ({ slice }) => {
  return (
    <StyledComponent>
      <Container maxWidth="xl" disableGutters className="slice-container">
        <Typography variant="h5" component="div">
          {RichText.render(slice?.primary?.movie_slider_title.raw)}
        </Typography>
        <Typography component="div">
          {RichText.render(slice?.primary?.movie_slider_text.raw)}
        </Typography>
        <MovieSlider items={slice.items} />
        <Container maxWidth="lg" className="content">
          <CallToActions item={slice?.primary} />
        </Container>
      </Container>
    </StyledComponent>
  );
};

export default MovieSliderImages;
