import React from "react";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Grid, Typography } from "@material-ui/core";

import Container from "@landingpage/core/src/components/Container";
import {
  ResponsiveImage,
  ResponsiveBackgroundImage,
} from "@landingpage/core/src/components/Image";

import UspList from "./parts/UspList";
import CtaList from "./parts/CtaList";

import StyledComponent from "./Header.style";

const BackgroundChildren = ({ header, children }) => {
  if (header?.background_image.fluid) {
    return (
      <ResponsiveBackgroundImage image={header?.background_image} cover>
        {children}
      </ResponsiveBackgroundImage>
    );
  }

  return children;
};

const Content = ({ header }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <Grid
      container
      className="textContainer"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="contentCenter">
        <Grid container direction="column" spacing={4}>
          <Grid className="mobile-center" item>
            <Typography className="title" variant="h1">
              {RichText.asText(header.title.raw)}
            </Typography>

            {isMobile && <ResponsiveImage image={header.image} />}

            <Typography
              className="description"
              variant="body1"
              component="div"
              gutterBottom
            >
              {RichText.render(header.description.raw)}
            </Typography>
          </Grid>
          <Grid item>
            <UspList items={header.unique_selling_points} />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="imagePosition" item xs={12} sm={12} md={5} lg={5} xl={5}>
        <div className="deviceImage">
          {!isMobile && <ResponsiveImage image={header.image} />}
        </div>
      </Grid>
    </Grid>
  );
};

const HeaderImage = ({ header }) => {
  if (!header) return null;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <StyledComponent>
      <Container marginTop={!isMobile}>
        {!isMobile && <Content header={header} />}

        {isMobile && (
          <BackgroundChildren header={header} cover>
            <>
              <Grid
                container
                className="textContainer"
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                  <Content header={header} />
                </Grid>
              </Grid>
            </>
          </BackgroundChildren>
        )}
      </Container>
    </StyledComponent>
  );
};

HeaderImage.defaultProps = {
  header: null,
};

HeaderImage.propTypes = {};

export default HeaderImage;
