import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { RichText } from "prismic-reactjs";

import StyledComponent from "./UspList.style";

const UspColumn = ({ columns }) => {
  return columns.map((items, columnIndex) => (
    <Grid
      className="usp-list-container"
      key={columnIndex}
      item
      md={6}
      xs={12}
      sm={12}
    >
      <List dense disablePadding>
        {items.map((item, itemIndex) => (
          <ListItem key={itemIndex} disableGutters>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText primary={RichText.render(item.usp_title.raw)} />
          </ListItem>
        ))}
      </List>
    </Grid>
  ));
};

const UspList = ({ items }) => {
  if (!items || !items.length) return null;

  const sliceIndex = Math.ceil(items.length / 2);
  let columns = [items.slice(0, sliceIndex), items.slice(sliceIndex)];

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  if (isMobile) {
    columns = [items.slice(0, 3)];
  }

  return (
    <Grid item xs={12}>
      <StyledComponent>
        <Grid container direction="row" justifyContent="center" spacing={2}>
          <UspColumn columns={columns} />
        </Grid>
      </StyledComponent>
    </Grid>
  );
};

UspList.defaultProps = {
  items: [],
};

UspList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      usp_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired
  ),
};

export default UspList;
