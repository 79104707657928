import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Button from "../Button";

const api = axios.create({
  baseURL: process.env.GATSBY_APP_CATALOG_URL,
});

const DialogTitle = withStyles()((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" style={{ fontSize: "1.5rem" }}>
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className="closeButton"
            onClick={onClose}
            style={{ color: "#d80625" }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({}))(MuiDialogContent);

const MovieDialog = ({ open, handleClose, asset }) => {
  const { t } = useTranslation();
  const [assetData, setAssetData] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  useEffect(() => {
    if (asset) {
      api
        .get(
          `v1/${asset.asset_subtype.toLowerCase()}/${
            asset.id
          }?translation=${window.location.href
            .match(/\/[a-z]{2}-[a-z]{2}/g)[0]
            .split("-")[0]
            .toLowerCase()
            .replace("/", "")}`
        )
        .then((response) => setAssetData(response.data));
    }
  }, [asset]);

  if (!assetData || !asset) return null;
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <div
          className="dialogContent"
          style={{
            display: "flex",
          }}
        >
          <div className="imageContainer">
            {!isMobile && (
              <img
                src={`${process.env.GATSBY_APP_RESOURCES_URL}/${asset.id}/list/360x520/${asset.list_image}.jpeg`}
                alt="cover"
                className="dialogImage"
                style={{
                  maxHeight: "260px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginLeft: "20px",
                }}
              />
            )}
          </div>
          <div className="contentContainer">
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
              style={{ paddingBottom: "0" }}
            >
              {assetData.title}
            </DialogTitle>
            <DialogContent>
              <Typography gutterBottom style={{ color: "black" }}>
                {assetData.short_description}
              </Typography>
              <div style={{ position: "absolute", bottom: "8%" }}>
                {!isMobile && (
                  <Button
                    color="primary"
                    href={`${process.env.GATSBY_PLATFORM_URL}/${
                      assetData.asset_subtype === "Movie" ? "video" : "serie"
                    }/${assetData.id}`}
                  >
                    {t("read_more")}
                  </Button>
                )}
              </div>
              <div>
                {isMobile && (
                  <Button
                    color="primary"
                    href={`${process.env.GATSBY_PLATFORM_URL}/${
                      assetData.asset_subtype === "movie" ? "video" : "serie"
                    }/${assetData.id}`}
                  >
                    {t("read_more")}
                  </Button>
                )}
              </div>
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MovieDialog;
