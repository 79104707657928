import React from "react";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Grid, Typography } from "@material-ui/core";

import Container from "@landingpage/core/src/components/Container";
import ResponsiveImage from "../Image/ResponsiveImage";
import StyledComponent from "./Header.style";

const Content = ({ header }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <Grid
      container
      className="textContainer"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={12} md={7} lg={7} xl={7} className="contentCenter">
        <Grid container direction="column" spacing={4}>
          <Grid className="mobile-center" item>
            <Typography className="title" variant="h1">
              {RichText.asText(header.primary.header_title.raw)}
            </Typography>

            {isMobile && <ResponsiveImage image={header.primary.image} />}

            <Typography
              className="description"
              variant="body1"
              component="div"
              gutterBottom
            >
              {RichText.render(header.primary.header_description.raw)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="imagePosition" item xs={12} sm={12} md={5} lg={5} xl={5}>
        <div className="deviceImage">
          {!isMobile && <ResponsiveImage image={header.primary.image} />}
        </div>
      </Grid>
    </Grid>
  );
};

const Header = ({ header }) => {
  if (!header) return null;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <StyledComponent className="default">
      <Container paddingRight={false}>
        {!isMobile && <Content header={header} />}
        {isMobile && (
          <>
            <Content header={header} />
          </>
        )}
      </Container>
    </StyledComponent>
  );
};

Header.defaultProps = {
  header: null,
};

Header.propTypes = {};

export default Header;
