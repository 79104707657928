import styled from "styled-components";
import { transparentize } from "polished";

const StyledComponent = styled.header`
  background: ${({ theme }) => theme.palette.background.header};
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  .description,
  .description > p {
    margin-bottom: 0;
  }

  .MuiTypography-h1 {
    color: ${({ theme }) => theme.palette.text.header};
    font-size: 3rem;
    ${({ theme }) => theme.breakpoints.down("md")} {
      font-size: 2.5rem;
    }
  }
  .MuiTypography-body2 {
    color: ${({ theme }) => theme.palette.text.header};
  }

  .text {
    padding: ${({ theme }) => `${theme.spacing(8)}px ${theme.spacing(6)}px`};
    &.bg {
      background: ${({ theme }) =>
        `linear-gradient(90deg, ${
          theme.palette.background.header
        } 70%, ${transparentize(1, theme.palette.background.header)} 100%)`};
    }
  }

  a:link {
    color: ${({ theme }) => theme.palette.text.header};
  }
  a:visited {
    color: ${({ theme }) => theme.palette.text.header};
  }

  .MuiLink-underlineHover {
    text-decoration: underline;
  }

  .textContainer {
    padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
    padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};

    ${({ theme }) => theme.breakpoints.only("md")} {
      padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("sm")} {
      padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
      padding-bottom: ${({ theme }) => `${theme.spacing(0)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("xs")} {
      padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
      padding-bottom: ${({ theme }) => `${theme.spacing(0)}px`};
    }
  }

  .desktopLogin {
    width: 70%;

    .MuiButtonBase-root {
      width: 100%;
      margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      width: 100%;
    }
  }

  .inputEmail {
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiButtonBase-root {
      width: 100%;
      margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiButtonBase-root {
        width: 100%;
        margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
      }
    }
  }

  .scrollButton {
    color: #ffffff;
    border: 3px;
    border-style: solid;
    border-radius: 30px;
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
    margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
    display: flex;
  }

  .imagePosition {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .deviceImage {
      width: 100%;
      position: relative;
    }
  }

  .mobile-scroll {
    text-align: -webkit-center;
  }

  .titleMobile {
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
    text-align: center;
  }

  .text {
    padding: ${({ theme }) => `${theme.spacing(8)}px ${theme.spacing(4)}px`};

    &.noPaddingTop {
      padding-top: 0;
    }
  }

  .contentCenter {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .call-to-action {
    margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${({ theme }) => `0px 0px ${theme.spacing(5)}px 0px`};

    .text {
      background: transparent;
      padding: ${({ theme }) => `0px ${theme.spacing(3)}px`};
    }

    .gatsby-image-wrapper {
      z-index: -1;
      margin-bottom: ${({ theme }) => `-${theme.spacing(6)}px`};
    }
  }
  .formItem {
    padding-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
  }
  .invitedByBox {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  .checkboxtext {
    .MuiTypography-body1 {
      font-size: 0.9rem;
    }
  }
  .invitedByText {
    font-weight: bold;
  }

  .MuiInputLabel-filled {
    color: #033360 !important;
  }

  .MuiFilledInput-input {
    color: #033360 !important;
  }

  .MuiFormControlLabel-label {
    color: #033360 !important;
  }

  .MuiInputBase-formControl {
    background: #f5f5f5 !important;
  }
`;

export default StyledComponent;
