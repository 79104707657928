import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { Grid, Paper, Typography } from "@material-ui/core";

import { Image, ResponsiveBackgroundImage } from "../Image";
import Button from "../Button";
import UspList from "./UspList";
import SelectPlan from "./SelectPlan";

import Container from "../Container";

import StyledComponent from "./Pricing.style";

const CtaButton = ({ slice }) => {
  if (!slice.cta_title || !slice.cta_link) return null;

  return (
    <Grid item xs={12}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        href={slice.cta_link.url}
        target={slice.cta_link.target}
      >
        {RichText.asText(slice.cta_title.raw)}
      </Button>
    </Grid>
  );
};

const ImageComponent = ({ image }) => {
  if (!image.fluid) return null;

  return (
    <Grid item xs={12}>
      <Image image={image} />
    </Grid>
  );
};

const Background = ({ slice, bottomMargin, children }) => {
  const content = (
    <div
      className={`contentContainer ${
        slice?.primary?.background_image?.fluid ? null : "withBg"
      } ${bottomMargin ? "bottomMargin" : null}`}
    >
      <div className="contentPadding">{children}</div>
    </div>
  );

  if (slice?.primary?.background_image?.localFile)
    return (
      <ResponsiveBackgroundImage image={slice?.primary?.background_image} cover>
        {content}
      </ResponsiveBackgroundImage>
    );

  return content;
};

const Pricing = ({ slice, bottomMargin }) => {
  return (
    <StyledComponent
      id={RichText.asText(slice.primary?.pricing_anchor?.raw || [])}
    >
      <Background slice={slice} bottomMargin={bottomMargin}>
        <Container className="contentCenter">
          <Paper className="pricing-card">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  {RichText.asText(slice?.primary.pricing_title?.raw)}
                </Typography>
              </Grid>
              <UspList items={slice.items} />
              <SelectPlan {...slice.primary} />
              <CtaButton slice={slice.primary} />
              <ImageComponent image={slice.primary.image} />
            </Grid>
          </Paper>
        </Container>
      </Background>
    </StyledComponent>
  );
};

Pricing.defaultProps = {
  bottomMargin: false,
};

Pricing.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      pricing_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        usp_title: PropTypes.shape({
          raw: PropTypes.array.isRequired,
        }).isRequired,
      })
    ),
  }).isRequired,
  bottomMargin: PropTypes.bool,
};

export default Pricing;
