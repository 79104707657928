import styled from "styled-components";
import { transparentize } from "polished";

const StyledComponent = styled.header`
  .description,
  .description > p {
    margin-bottom: 0;
  }

  .text {
    padding: ${({ theme }) => `${theme.spacing(8)}px ${theme.spacing(6)}px`};
    &.bg {
      background: ${({ theme }) =>
        `linear-gradient(90deg, ${
          theme.palette.background.default
        } 70%, ${transparentize(1, theme.palette.background.default)} 100%)`};
    }
  }

  .videoDevice {
    ${({ theme }) => theme.breakpoints.down("md")} {
      height: 100%;
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      height: 100%;
    }
  }

  .textContainer {
    padding-top: ${({ theme }) => `${theme.spacing(8)}px`};
    padding-bottom: ${({ theme }) => `${theme.spacing(8)}px`};

    ${({ theme }) => theme.breakpoints.only("md")} {
      padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("sm")} {
      padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
    }

    ${({ theme }) => theme.breakpoints.only("xs")} {
      padding-top: ${({ theme }) => `${theme.spacing(2)}px`};
    }
  }

  .desktopLogin {
    width: 70%;

    .MuiButtonBase-root {
      width: 100%;
      margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      width: 100%;
    }
  }

  .inputEmail {
    .MuiFormControl-root {
      width: 100%;
    }
    .MuiButtonBase-root {
      width: 100%;
      margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      .MuiFormControl-root {
        width: 100%;
      }
      .MuiButtonBase-root {
        width: 100%;
        margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
      }
    }
  }

  .scrollButton {
    color: #ffffff;
    border: 3px;
    border-style: solid;
    border-radius: 30px;
    margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
    display: flex;
  }

  .imagePosition {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .deviceImage {
      width: 100%;
      position: relative;
    }
  }

  .mobile-scroll {
    text-align: -webkit-center;
  }

  .titleMobile {
    margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
    text-align: center;
  }

  .text {
    padding: ${({ theme }) => `${theme.spacing(8)}px ${theme.spacing(4)}px`};

    &.noPaddingTop {
      padding-top: 0;
    }
  }

  .contentCenter {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .call-to-action {
    margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
  }

  .registerForm {
    .formItem, .MuiAlert-root {
      margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
    }

    .MuiFormControlLabel-label {
      font-size: 0.9em;
    }

    .MuiButton-root {
      margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
    }

    .MuiFormHelperText-root {
      color: white;
    }
  }

  .actionLogo {
    width: 250px;
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: ${({ theme }) => `0px 0px ${theme.spacing(4)}px 0px`};

    .text {
      background: transparent;
      padding: ${({ theme }) => `0px ${theme.spacing(3)}px`};
    }

    .gatsby-image-wrapper {
      z-index: -1;
      margin-bottom: ${({ theme }) => `-${theme.spacing(6)}px`};
    }
  }
`;

export default StyledComponent;
