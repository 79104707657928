import styled from "styled-components";

const StyledComponent = styled.div`
  background-color: ${({ theme }) => theme.palette.background.faq};

  h2 {
    color: ${({ theme }) => theme.palette.text.secondary};
    text-align: center;
    padding-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }

  .MuiAccordionSummary-content  {
    .MuiTypography-root{
    font-weight: bold;
    }
  }

  .MuiCollapse-container {
    margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
  }

  .MuiCollapse-wrapperInner {
    margin-top: ${({ theme }) => `${theme.spacing(-3)}px`};
  }

  .expansion-panel {
    background-color: ${({ theme }) => theme.palette.faq.expansionPanel};;

    p,
    p > a,
    ul,
    svg {
      color: ${({ theme }) => theme.palette.faq.text};
    }
  }
`;

export default StyledComponent;
