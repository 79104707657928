import styled from "styled-components";

const StyledComponent = styled.div`
  p,
  a {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.palette?.cookie?.text || "#000000"};
  }

  .content {
    flex: none !important;

    width: 100%;
    ${({ theme }) => theme.breakpoints.only("sm")} {
      width: 90%;
    }
  }
  
  .acceptButton {
    background-color: ${({ theme }) =>
      theme.palette?.cookie?.acceptButton || "#116FF3"} !important;
    color: ${({ theme }) => theme.palette?.cookie?.acceptText || "#FFFFFF"}  !important;
    border-radius: 2px !important;
    min-width: 40% !important;
    text-transform: uppercase !important;
  }

  .declineButton {
    background-color: ${({ theme }) =>
      theme.palette?.cookie?.background || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.palette?.cookie?.declineButton || "#116FF3"} !important;
    border-color: ${({ theme }) =>
      theme.palette?.cookie?.declineButton || "#116FF3"} !important;
    border: 1px !important;
    border-style: solid !important;
    border-radius: 2px !important;
    min-width: 30% !important;
    text-transform: uppercase !important;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .container {
    background-color: ${({ theme }) =>
      theme.palette?.cookie?.background || "#FFFFFF"} !important;
    color: ${({ theme }) => theme.palette?.cookie?.text || "#000000"} !important;
    display: flex !important;
  }
`;

export default StyledComponent;
