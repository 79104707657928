/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import Button from "../Button";

import StyledComponent from "./SelectPlan.style";

const SelectPlanButton = ({
  name,
  index,
  title,
  price_monthly,
  price_yearly,
  selected,
  onChange,
  currency_symbol,
}) => {
  const { t } = useTranslation();

  const changePlan = () => {
    onChange(index);
  };

  return (
    <div>
      <Paper
        onClick={changePlan}
        className={selected ? "planBox selected" : "planBox"}
      >
        <div className="content">
          {name === "yearly" && (
            <div className="ribbon-wrapper">
              <div className="ribbon">
                <Typography noWrap>{t("popular")}</Typography>
              </div>
            </div>
          )}

          <div>
            <Typography noWrap className="plan-title" variant="h5">
              {RichText.asText(title.raw)}
            </Typography>
          </div>

          <div>
            <div className="priceContainer">
              {selected && <CheckCircleIcon className="checkedRadioIcon" />}
              {!selected && (
                <RadioButtonUnchecked className="radioButtonUnchecked" />
              )}

              <Typography noWrap className="plan-price">
                {`${currency_symbol} ${RichText.asText(price_monthly?.raw)} `}
                <span> {t("per_month")}</span>
              </Typography>
            </div>
          </div>
        </div>

        <div className="ribbonContainer">
          <div className="yearRibbon">
            <Typography noWrap variant="h6">
              {`${currency_symbol} ${RichText.asText(price_yearly?.raw)} `}
              {t("per_year")}
            </Typography>
          </div>
        </div>
      </Paper>
    </div>
  );
};

const SelectPlan = (props) => {
  const [selectedValue, setSelectedValue] = React.useState(
    props?.selected_plan === "monthly" ? 0 : 1
  );
  const changePlan = (plan) => {
    setSelectedValue(plan);

    if (props.onChange) {
      props.onChange(plan === 0 ? "monthly" : "yearly");
    }
  };

  return (
    <Grid item xs={12}>
      <StyledComponent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          {props.month_monthly_price && (
            <Grid item xs={12} sm={12} md={6}>
              <SelectPlanButton
                name="monthly"
                index={0}
                title={props.monthly_title}
                price_monthly={props.month_monthly_price}
                price_yearly={props.month_yearly_price}
                currency_symbol={props.currency_symbol}
                selected={selectedValue === 0}
                onChange={changePlan}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={props.month_monthly_price ? 6 : 12}>
            <SelectPlanButton
              name="yearly"
              index={1}
              title={props.yearly_title}
              price_monthly={props.year_monthly_price}
              price_yearly={props.year_yearly_price}
              currency_symbol={props.currency_symbol}
              selected={selectedValue === 1}
              onChange={changePlan}
            />
          </Grid>
        </Grid>
      </StyledComponent>
    </Grid>
  );
};

export { SelectPlanButton };

export default SelectPlan;
