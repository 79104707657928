import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import {
  useMediaQuery,
  Container,
  Grid,
  Typography,
  Collapse,
} from "@material-ui/core";
import PlusIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import Button from "@landingpage/core/src/components/Button";
import {
  ResponsiveImage,
  ResponsiveBackgroundImage,
} from "@landingpage/core/src/components/Image";

import StyledComponent from "./ContentBlock.style";

const MaxWidthContainer = ({ children, maxWidth }) => {
  if (maxWidth) {
    return (
      <div className="maxWidthContainer">
        <div className="maxWidthChildren">{children}</div>
      </div>
    );
  }
  return children;
};

const Text = ({
  slice,
  imageDirection,
  isMobile,
  readMore,
  onReadMoreClick,
}) => {
  let firstParagraph = slice.primary.content_description.raw;
  let secondParagraph = null;

  if (
    slice.primary.read_more_button &&
    Array.isArray(slice.primary.content_description.raw)
  ) {
    firstParagraph = slice.primary.content_description.raw.slice(0, 1);

    if (slice.primary.content_description.raw.length > 1) {
      secondParagraph = slice.primary.content_description.raw.slice(-1);
    }
  }

  return (
    <>
      <Typography variant="h2" id="contentAnchor">
        {RichText.asText(slice.primary.content_title.raw)}
      </Typography>

      {!isMobile && imageDirection === "top" && (
        <ResponsiveImage
          className="image"
          image={slice.primary.content_image}
        />
      )}

      <Typography variant="body1" component="div">
        {RichText.render(firstParagraph)}
      </Typography>
      {slice.primary.read_more_button && secondParagraph && (
        <Collapse in={readMore}>
          <Typography variant="body1" component="div" gutterBottom>
            {RichText.render(secondParagraph)}
          </Typography>
        </Collapse>
      )}

      <Quote
        quote={slice.primary.quote_text}
        person={slice.primary.quote_person}
      />

      {isMobile && (
        <ResponsiveImage
          className="image"
          image={slice.primary.content_image}
        />
      )}

      <div>
        {slice.primary.read_more_button && secondParagraph && (
          <ReadMoreButton
            item={slice.primary}
            onClick={onReadMoreClick}
            readMore={readMore}
          />
        )}
        <CallToActions items={slice.items} />
      </div>

      {!isMobile && imageDirection === "bottom" && (
        <ResponsiveImage
          className="image"
          image={slice.primary.content_image}
        />
      )}
    </>
  );
};

const Quote = ({ quote, person }) => {
  if (!quote?.raw?.length) return null;

  return (
    <>
      <Typography className="quote-text" variant="h4" component="div">
        {RichText.render(quote.raw)}
      </Typography>
      <Typography className="quote-person" variant="body1" gutterBottom>
        <small>{RichText.asText(person.raw)}</small>
      </Typography>
    </>
  );
};

const ReadMoreButton = ({ item, onClick, readMore }) => {
  if (!readMore) {
    return (
      <Button
        className="call-to-action readMoreButton"
        variant="outlined"
        color="default"
        onClick={onClick}
      >
        {RichText.asText(item.read_more_text.raw)}
        <PlusIcon className="readMoreIcon" />
      </Button>
    );
  }

  return (
    <Button
      className="call-to-action readMoreButton"
      variant="outlined"
      color="default"
      onClick={onClick}
    >
      {RichText.asText(item.read_less_text.raw)}
      <RemoveIcon className="readMoreIcon" />
    </Button>
  );
};

const CallToActions = ({ items }) => {
  if (!items?.length) return null;

  return items.map((callToAction, index) => {
    const classNames = ["call-to-action"];

    if (callToAction.cta_color !== "default") {
      classNames.push(callToAction.cta_color);
    }

    if (callToAction.cta_title.raw.length === 0) return null;
    return (
      <Button
        className={classNames.join(" ")}
        key={index}
        variant="contained"
        color="primary"
        href={callToAction.cta_link.url}
        target={callToAction.cta_link.target}
      >
        {RichText.asText(callToAction.cta_title.raw)}
      </Button>
    );
  });
};

const columnSizes = (sizes, inverse = false) => {
  const calculateSize = (size, inverseValue) => {
    if (inverseValue && size < 12) {
      return 12 - size;
    }

    return size;
  };

  return {
    xs: calculateSize(sizes.xs, inverse),
    sm: calculateSize(sizes.sm, inverse),
    md: calculateSize(sizes.md, inverse),
    lg: calculateSize(sizes.lg, inverse),
    xl: calculateSize(sizes.xl, inverse),
  };
};

const ContentBlock = ({ slice }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  
  const [readMore, setReadMore] = useState(false);

  const imageDirection = slice.primary.image_direction;
  const imageType = slice.primary.image_type;

  let columns = {
    xs: parseInt(slice?.primary?.grid_xs) || 12,
    sm: parseInt(slice?.primary?.grid_small) || 12,
    md: parseInt(slice?.primary?.grid_medium) || 7,
    lg: parseInt(slice?.primary?.grid_large) || 6,
    xl: parseInt(slice?.primary?.grid_xl) || 5,
  };

  const classNames = [
    `image-${imageDirection}`,
    `image-${imageType}`,
    "slice-container",
    "mobile-center",
  ];

  let direction = "row";
  let position = "center right";

  if (["left", "top"].indexOf(imageDirection) > -1) {
    direction = "row-reverse";
    position = "center left";
  }
  if (["bottom", "top"].indexOf(imageDirection) > -1) {
    columns = {
      ...columns,
      md: 12,
      lg: 12,
      xl: 12,
    };
    position = "center bottom";
    classNames.push("center");
  }

  const onReadMoreClick = () => {
    setReadMore(!readMore);
  };

  const styledComponentClasses = useMemo(() => {
    return [
      `background-${slice.primary.background_color || "default"}`,
      readMore ? "readMore" : null,
    ];
  }, [slice, readMore]);

  return (
    <StyledComponent className={styledComponentClasses.join(" ")}>
      <MaxWidthContainer maxWidth={slice.primary.maxwidth}>
        <Container maxWidth="xl" disableGutters>
          {imageType === "fade" && !isMobile && (
            <ResponsiveBackgroundImage
              image={slice.primary.content_image}
              position={position}
            >
              <Grid
                className={classNames.join(" ")}
                container
                direction={direction}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="text" item {...columnSizes(columns)}>
                  <Text
                    slice={slice}
                    readMore={readMore}
                    onReadMoreClick={onReadMoreClick}
                  />
                </Grid>
              </Grid>
            </ResponsiveBackgroundImage>
          )}

          {imageType === "default" &&
            ["right", "left"].indexOf(imageDirection) > -1 &&
            !isMobile && (
              <Grid
                className={classNames.join(" ")}
                container
                direction={direction}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="text" item {...columnSizes(columns)}>
                  <Text
                    slice={slice}
                    readMore={readMore}
                    onReadMoreClick={onReadMoreClick}
                  />
                </Grid>
                <Grid className="image" item {...columnSizes(columns, true)}>
                  <ResponsiveImage image={slice.primary.content_image} />
                </Grid>
              </Grid>
            )}

          {imageType === "default" &&
            ["top", "bottom"].indexOf(imageDirection) > -1 &&
            !isMobile && (
              <Grid className={classNames.join(" ")} container>
                <Grid className="text" item xs={12}>
                  <Text
                    imageDirection={imageDirection}
                    slice={slice}
                    readMore={readMore}
                    onReadMoreClick={onReadMoreClick}
                  />
                </Grid>
              </Grid>
            )}

          {isMobile && (
            <Grid
              className={classNames.join(" ")}
              container
              direction={direction}
              alignItems="center"
            >
              <Grid className="text" item {...columnSizes(columns)}>
                <Text
                  isMobile
                  slice={slice}
                  readMore={readMore}
                  onReadMoreClick={onReadMoreClick}
                />
              </Grid>
            </Grid>
          )}
        </Container>
      </MaxWidthContainer>
    </StyledComponent>
  );
};

ContentBlock.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string.isRequired,

    primary: PropTypes.shape({
      content_title: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,
      content_description: PropTypes.shape({
        raw: PropTypes.array.isRequired,
      }).isRequired,

      quote_text: PropTypes.shape({
        raw: PropTypes.array,
      }),
      quote_person: PropTypes.shape({
        raw: PropTypes.array,
      }),
      read_more_button: PropTypes.bool,
    }).isRequired,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        cta_title: PropTypes.shape({
          raw: PropTypes.array,
        }),
        cta_link: PropTypes.shape({
          url: PropTypes.string,
          target: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
};

export default ContentBlock;
