import styled from "styled-components";

const StyledComponent = styled.div`
  ul {
    li {
      > div {
        > svg {
          color: ${({ theme }) => theme.palette.color.green};
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    ul {
      li {
        padding: 0px;

        > div {
          min-width: ${({ theme }) => `${theme.spacing(6)}px`};

          > svg {
            width: 1.2em;
            height: 1.2em;
          }
        }
      }
    }
  }
`;

export default StyledComponent;
