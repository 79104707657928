import styled from "styled-components";

const StyledComponent = styled.div`
  .contentCenter {
    display: flex;
    justify-content: center;
  }

  .contentPadding {
    padding: ${({ theme }) => `${theme.spacing(10)}px 0px`};
    ${({ theme }) => theme.breakpoints.down("sm")} {
      padding: ${({ theme }) => `${theme.spacing(0)}px 0px`};
    }
  }

  
  .contentContainer {
    &.bottomMargin {
      margin-bottom: ${({ theme }) => `${theme.spacing(10)}px`};
    }

    &.withBg {
      background-color: ${({ theme }) => theme.palette.pricing.background};
    }

    .pricing-card {
      max-width: 980px;
      background-color: ${({ theme }) => theme.palette.background.price};
      padding: ${({ theme }) => `${theme.spacing(6)}px ${theme.spacing(6)}px`};
      position: relative;

      h2 {
        color: ${({ theme }) => theme.palette.text.secondary};
        text-align: center;
      }
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
      margin: 0px;
      padding: 0px;

      .pricing-card {
        margin: ${({ theme }) => `${theme.spacing(4)}px 0px`};
        padding: ${({ theme }) =>
          `${theme.spacing(4)}px ${theme.spacing(4)}px`};
      }
    }
  }
`;

export default StyledComponent;
